import type { ComponentType } from "react"

export const Generic_Error = (Component): ComponentType => {
    return (props) => {
        props.className += " error_gen"
        return <Component {...props} style={{ display: "none" }} />
    }
}

export const Invalid_Error = (Component): ComponentType => {
    return (props) => {
        props.className += " error_invalid"
        return <Component {...props} style={{ display: "none" }} />
    }
}

export const Existing_Error = (Component): ComponentType => {
    return (props) => {
        props.className += " error_existing"
        return <Component {...props} style={{ display: "none" }} />
    }
}
